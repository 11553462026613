import { RadioButtonProps } from '@everlywell/leaves';
import Grid from 'components/Grid';
import { snakeCase } from 'lodash';
import React, { useState } from 'react';

import * as S from './AppointmentTypeRadioOption.styles';

export type AppointmentTypeRadioOptionProps = Omit<RadioButtonProps, 'id'> & {
  name: string;
  label: string;
  subLabel?: string;
  value: string;
  checked?: boolean;
};

/**
 * This component represents a radio button option for a AppointmentType.
 */
const AppointmentTypeRadioOption = React.forwardRef<
  HTMLInputElement,
  AppointmentTypeRadioOptionProps
>((props, ref) => {
  const { name, label, value, subLabel, checked, ...rest } = props;
  const [id] = useState(snakeCase(`${props.name} ${props.value}`));

  return (
    <S.AppointmentTypeRadioButton
      {...rest}
      ref={ref}
      id={id}
      name={name}
      value={value}
      checked={checked}
      border
      label={
        <Grid.Container spacing={['xs2']}>
          <Grid.Item width={[1]}>
            <S.LabelWrapper>
              <S.AppointmentTypeDetailsWrapper>
                <S.Label data-notranslate="true">{label}</S.Label>
                {subLabel && (
                  <S.SubLabel data-notranslate="true">{subLabel}</S.SubLabel>
                )}
              </S.AppointmentTypeDetailsWrapper>
            </S.LabelWrapper>
          </Grid.Item>
        </Grid.Container>
      }
    />
  );
});

export default AppointmentTypeRadioOption;
