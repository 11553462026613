import { formatPhoneNumber } from 'common/utils/helpers';
import { PhoneQuestionMarkerIcon } from 'components/telehealth/Enterprise/Icons';
import React from 'react';

import * as S from './NeedHelpCard.styles';
import { CARE_AVAILABILITY } from './utils/constants';

export type NeedHelpCardProps = {
  onClick: () => void;
  header: string;
  phoneNumber: string;
};

/**
 * Section to display help information for the user
 */
function NeedHelpCard({ onClick, header, phoneNumber }: NeedHelpCardProps) {
  return (
    <S.Container>
      <S.Header>
        <PhoneQuestionMarkerIcon />
        <S.Heading> {header} </S.Heading>
      </S.Header>

      <S.Text>
        Contact your care team at{' '}
        <a href={`tel:+1${phoneNumber}`} onClick={onClick}>
          {formatPhoneNumber(phoneNumber)}
        </a>
        <br />
        {CARE_AVAILABILITY}
      </S.Text>
    </S.Container>
  );
}

export default NeedHelpCard;
