import { Heading, Input, Text, VStack } from '@everlywell/ui-kit';
import React from 'react';
import { useFormContext } from 'react-hook-form';

import { FormBuilderField } from '../../utils/types';

export type WeightFieldProps = FormBuilderField;

function WeightField(props: WeightFieldProps) {
  const { id, required, label } = props;
  const { register, errors } = useFormContext();

  return (
    <VStack
      alignItems="flex-start"
      data-test="weight-field-v2"
      gap="4"
      width="100%"
    >
      <Heading as="h3" fontSize="4xl" paddingRight="6">
        {label}
      </Heading>
      <Input
        id={id}
        min={1}
        name={id}
        placeholder="lb"
        ref={register({
          required: required ? 'Please enter your weight' : false,
        })}
        required={required}
        type="number"
      />
      {errors && (
        <Text color="utility.warning" fontSize="sm" marginBottom="4">
          {errors[id]?.message}
        </Text>
      )}
    </VStack>
  );
}

export default WeightField;
