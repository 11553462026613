import {
  colors,
  typography,
  size,
  H3,
  Button as LeavesButton,
  Container as BaseContainer,
  Row as BaseRow,
  Col as BaseCol,
  mediaQueries,
} from '@everlywell/leaves';
import { bodyCopy } from 'common/styles/app-styles';
import styled from 'styled-components';

const Title = styled.h3`
  ${bodyCopy};
  color: ${colors.gray5};
  font-weight: ${typography.weight.regular};
  text-align: left;
  margin: 0 0 ${size.xs1}px;
`;

const InfoBox = styled.div`
  display: flex;
  align-items: left;
  padding: 10px 16px;
`;

const Header = styled(H3)`
  ${bodyCopy};
  font-size: ${size.lg}px;
  text-align: left;
  margin: 0 0 8px;
`;

const ManageButton = styled(LeavesButton)`
  width: 100%;

  ${mediaQueries.forTabletHorizontalUp} {
    width: 240px;
  }
`;

const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;
  padding: ${size.md}px;
`;

const Row = styled(BaseRow)`
  display: flex;
  justify-content: center;
  align-items: stretch;
  margin-top: ${size.md}px;
`;

const Col = styled(BaseCol)`
  flex: 1;
  padding: ${size.xs1}px;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;
`;

const StyledButton = styled(LeavesButton)`
  width: 100%;
  padding: ${size.sm}px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: normal;
  text-align: center;
`;

export {
  Title,
  InfoBox,
  Header,
  ManageButton,
  Container,
  Row,
  Col,
  StyledButton,
};
