import { TelehealthCustomModule } from 'common/apis/telehealthApis';
import useProgramSlug from 'common/hooks/useProgramSlug';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React, { useEffect } from 'react';

import WeightFieldV2 from '../../componentsV2/WeightField';
import { useFormModalContext } from '../../providers';
import { FormBuilderField } from '../../utils/types';
import AgreeToAboveField from '../AgreeToAboveField/AgreeToAboveField';
import { AllergiesAutoCompleteField } from '../AllergiesAutoCompleteField/AllergiesAutoCompleteField';
import BloodPressureField from '../BloodPressureField';
import BMIField from '../BMIField';
import CheckboxFieldGroup from '../CheckboxFieldGroup';
import DateField from '../DateField';
import DOBField from '../DOBField/DOBField';
import DocumentField from '../DocumentField';
import DropdownField from '../DropdownField';
import HeightField from '../HeightField/HeightField';
import LabelField from '../LabelField/LabelField';
import LocationField from '../LocationField/LocationField';
import { MedicationsAutoCompleteField } from '../MedicationsAutoCompleteField/MedicationsAutoCompleteField';
import NameField from '../NameField/NameField';
import PhoneField from '../PhoneField/PhoneField';
import PulseField from '../PulseField';
import RadioFieldGroup from '../RadioFieldGroup';
import ReadOnlyField from '../ReadOnlyField';
import SnomedField from '../SnomedField/SnomedField';
import TextAreaField from '../TextAreaField';
import TextField from '../TextField';
import WaistCircumferenceField from '../WaistCircumference/WaistCircumferenceField';
import WeightField from '../WeightField/WeightField';
import useFieldTracking from './hooks/useFieldTracking';
import useFieldVisibility from './hooks/useFieldVisibility';
import { useHealthieExclusionLogic } from './hooks/useHealthieExclusionLogic';

export type FieldProps = FormBuilderField;

/**
 * Render or not the children based on the custom_module_condition.
 */
function Field(props: FieldProps) {
  const { telehealthFormV2 } = useFlags<{
    telehealthFormV2: boolean;
  }>();
  const { custom_module_condition, mod_type } = props;
  const { programSlug } = useProgramSlug();
  const label = useFieldTracking(props, programSlug);
  const isVisible = useFieldVisibility(custom_module_condition);

  const { setModalsToBeShown } = useFormModalContext();
  const shouldDisplayModal = useHealthieExclusionLogic();

  const fieldsMap = telehealthFormV2 ? fieldsMapV2 : fieldsMapV1;

  const Field =
    mod_type === 'document' && !label?.startsWith('[upload]')
      ? null
      : fieldsMap[mod_type];

  useEffect(() => {
    if (shouldDisplayModal) {
      setModalsToBeShown(true);
    }

    return () => {
      setModalsToBeShown(false);
    };
  }, [setModalsToBeShown, shouldDisplayModal]);

  if (!isVisible || !Field) {
    return null;
  }

  return <Field {...props} label={label} />;
}

export default Field;

const fieldsMapV1: Record<
  TelehealthCustomModule['mod_type'],
  React.FC<FormBuilderField> | null
> = {
  allergies: AllergiesAutoCompleteField,
  agree_to_above: AgreeToAboveField,
  'BMI(in.)': BMIField,
  blood_pressure: BloodPressureField,
  checkbox: CheckboxFieldGroup,
  date: DateField,
  dob: DOBField,
  document: DocumentField,
  dropdown: DropdownField,
  ethnicity: RadioFieldGroup,
  gender_identity: RadioFieldGroup,
  'Height (in.)': HeightField,
  horizontal_radio: RadioFieldGroup,
  label: LabelField,
  location: LocationField,
  medications: MedicationsAutoCompleteField,
  name: NameField,
  phone: PhoneField,
  pulse: PulseField,
  race: RadioFieldGroup,
  radio: RadioFieldGroup,
  read_only: ReadOnlyField,
  sex_at_birth: RadioFieldGroup,
  text: TextField,
  textarea: TextAreaField,
  Weight: WeightField,
  waist_circumference: WaistCircumferenceField,
  /*
    The type of SnomedField does not conform to the expected types defined by the Healthie API.
    To work around this, we use a double type assertion. First, we cast SnomedField to 'unknown',
    which is a way to bypass TypeScript's type checking. Then, we cast 'unknown' to 'React.FC<FormBuilderField>'.
    This forces TypeScript to treat SnomedField as a React Functional Component that takes FormBuilderField as its props.
  */
  snomed_radio: SnomedField as unknown as React.FC<FormBuilderField>,
};
const fieldsMapV2: Record<
  TelehealthCustomModule['mod_type'],
  React.FC<FormBuilderField> | null
> = {
  Weight: WeightFieldV2,
  // TODO: add the rest of the fields in the following MRs
  agree_to_above: null,
  allergies: null,
  blood_pressure: null,
  'BMI(in.)': null,
  checkbox: null,
  date: null,
  dob: null,
  document: null,
  dropdown: null,
  ethnicity: null,
  gender_identity: null,
  'Height (in.)': null,
  horizontal_radio: null,
  label: null,
  location: null,
  medications: null,
  name: null,
  phone: null,
  pulse: null,
  race: null,
  radio: null,
  read_only: null,
  sex_at_birth: null,
  snomed_radio: null,
  text: null,
  textarea: null,
  waist_circumference: null,
};
