import { INTAKE_INTRO_SCREEN } from 'common/utils/contentfulGraphQlQueries';

import { ENTRY_ID } from '../constants';

export const quizScreenResponseMock = {
  quizScreen: {
    __typename: 'QuizScreen',
    disclaimer: {
      json: {
        data: {},
        content: [
          {
            data: {},
            content: [
              {
                data: {},
                marks: [],
                value:
                  'Always seek the advice of your physician or other qualified health provider with any questions you may have regarding a medical condition. Never disregard professional medical advice or delay in seeking it because of something you have read on ',
                nodeType: 'text',
              },
              {
                data: {
                  uri: 'https://everlywell.com',
                },
                content: [
                  {
                    data: {},
                    marks: [],
                    value: 'www.Everlywell.com',
                    nodeType: 'text',
                  },
                ],
                nodeType: 'hyperlink',
              },
              {
                data: {},
                marks: [],
                value:
                  ' If you think you may have a medical emergency, call 911 immediately.',
                nodeType: 'text',
              },
            ],
            nodeType: 'paragraph',
          },
        ],
        nodeType: 'document',
      },
      __typename: 'QuizScreenDisclaimer',
    },
    backgroundColor: 'moonlight.base',
    eyebrow: 'Quiz',
    title: 'Get help figuring out the care you need',
    cta: {
      __typename: 'CtaModel',
      label: 'Get Started',
      url: '/telehealth/sessions',
    },
    bulletListCollection: {
      __typename: 'QuizScreenBulletListCollection',
      items: [
        {
          __typename: 'BulletItem',
          description: {
            json: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'This intake will take about 10 minutes to make sure your medical information is fully up-to-date and complete',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
            __typename: 'BulletItemDescription',
          },
          iconName: 'Clock',
        },
        {
          __typename: 'BulletItem',
          description: {
            json: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'You will need your government I.D so that we can verify your identity, which is required for these types of telehealth services',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
            __typename: 'BulletItemDescription',
          },
          iconName: 'IdentificationCard',
        },
        {
          __typename: 'BulletItem',
          description: {
            json: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value:
                        'You must have the full address of the pharmacy you wish to collect your prescription from',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
            __typename: 'BulletItemDescription',
          },
          iconName: 'MapPin',
        },
        {
          __typename: 'BulletItem',
          description: {
            json: {
              data: {},
              content: [
                {
                  data: {},
                  content: [
                    {
                      data: {},
                      marks: [],
                      value: 'Your data is protected subject to our ',
                      nodeType: 'text',
                    },
                    {
                      data: {},
                      marks: [
                        {
                          type: 'underline',
                        },
                      ],
                      value: 'Privacy Notice',
                      nodeType: 'text',
                    },
                  ],
                  nodeType: 'paragraph',
                },
              ],
              nodeType: 'document',
            },
            __typename: 'BulletItemDescription',
          },
          iconName: 'Lock',
        },
      ],
    },
    imagesCollection: {
      __typename: 'QuizScreenImagesCollection',
      items: [
        {
          __typename: 'MediaModel',
          desktopImage: {
            __typename: 'Asset',
            url: 'https://images.ctfassets.net/ydyvnem5zkxh/1RSbbjxGowEYlu6uxO2wnv/afbbcff8aa018c248cd625cc2c735fe5/Brand_Shape_-_Desktop.png',
          },
          mobileImage: {
            __typename: 'Asset',
            url: 'https://images.ctfassets.net/ydyvnem5zkxh/RqllUU79nYXuR6aYPAPU1/86489eeb460fe10f3b4502aa1e2a9a09/Brand_Shape_-_Mobile.png',
          },
        },
      ],
    },
  },
};

export const mocks = [
  {
    request: {
      query: INTAKE_INTRO_SCREEN,
      variables: {
        entryId: ENTRY_ID,
      },
    },
    result: {
      data: {
        ...quizScreenResponseMock,
      },
    },
  },
];
