import {
  theme,
  Box,
  Heading,
  Icon,
  Grid,
  GridItem,
  Container,
} from '@everlywell/ui-kit';
import { ArrowLeft } from '@phosphor-icons/react';
import useCarePlan from 'common/hooks/useCarePlan';
import useOrders from 'common/hooks/useOrders';
import { OrderParam } from 'common/hooks/useOrders/useOrders';
import useUser from 'common/hooks/useUser';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import Layout from 'components/Layout';
import PlanSummary from 'components/PlanSummary';
import CarePlanAccordion from 'components/telehealth/CarePlan/components/CarePlanAccordion/CarePlanAccordion';
import { CarePlanInfoCardProps } from 'components/telehealth/CarePlan/components/CarePlanInfoCard';
import { format } from 'date-fns';
import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import { formatOrdersAccordionItems } from './helpers';

export interface AthenaCarePlanPageProps {}

const planSummaryProps = {
  programName: 'UTI Virtual Care Visit',
  date: format(new Date(), 'do LLLL yyyy'),
};

// placeholder for actual program name and appointment date
const { programName, date } = planSummaryProps;

const AthenaCarePlanPage = (props: AthenaCarePlanPageProps) => {
  const { user } = useUser();
  const firstName = user?.first_name;

  const { space } = theme;

  const { appointmentId } = useParams();
  const {
    assessment,
    diagnosis,
    providerName,
    orders,
    isLoading: isLoadingCarePlan,
  } = useCarePlan(appointmentId ?? '');
  const {
    orders: ordersDetails,
    fetchOrders,
    isLoading: isLoadingOrders,
  } = useOrders([]);

  const handleButtonClick = (params: OrderParam[]) => {
    fetchOrders(params);
  };

  const formattedOrdersAccordionItems = formatOrdersAccordionItems(
    orders,
    ordersDetails,
    {
      handleClick: handleButtonClick,
      isLoading: isLoadingOrders,
    },
  );

  const formattedAccordionItems: CarePlanInfoCardProps[] = [
    {
      sectionName: 'Diagnosis',
      iconName: 'CircleDashed',
      items: diagnosis,
    },
    ...formattedOrdersAccordionItems,
  ];

  useEffect(() => {
    analytics.track({
      event: ANALYTICS.EVENTS.VIEWED_PAGE,
      data: {
        page: ANALYTICS.PAGES.ATHENA_CARE_PLAN.PAGE,
      },
    });
  }, []);

  // TODO: Add loading state
  // This is a temporary place holder for loading state, design still in progress
  if (isLoadingCarePlan) {
    return (
      <Layout>
        <p>Loading...</p>
      </Layout>
    );
  }

  return (
    <Layout>
      <Grid gap="8" spacing={6} alignEdges={false}>
        <GridItem width={[1]} paddingBottom="0">
          <Link to="/dashboard">
            <Icon
              as={ArrowLeft}
              size="sm"
              fill="tints.uiGrey"
              backgroundColor="tints.white"
              borderRadius="3xl"
              cursor="pointer"
              weight={'regular'}
              height={space[12]}
              padding={space[3]}
              width={space[12]}
              aria-label="Left arrow icon"
              aria-hidden="true"
            ></Icon>
          </Link>
        </GridItem>
        <GridItem width={[1]}>
          <Container
            size={['fluid', null, 'xl']}
            centerContent={true}
            marginLeft="auto"
            marginRight="auto"
            gap="8"
          >
            <Box>
              <Heading as="h1" size="xl" textAlign="center">
                {firstName ? (
                  <>
                    Hey {firstName}, your
                    <br />
                    care plan is ready
                  </>
                ) : (
                  <>Your care plan is ready</>
                )}
              </Heading>
            </Box>
            <Box
              display={{ base: '', tablet: 'flex' }}
              maxWidth={theme.breakpoints.desktop}
              marginLeft="auto"
              marginRight="auto"
            >
              <Grid>
                <GridItem width={[1, 1 / 3]}>
                  <Box paddingBottom={{ base: space[4], tablet: 0 }}>
                    <CarePlanAccordion items={formattedAccordionItems} />
                  </Box>
                </GridItem>
                <GridItem width={[1, 2 / 3]}>
                  <Box
                    marginLeft={[`calc(${space[6]} * (-1))`, 0, 0]}
                    marginRight={[`calc(${space[6]} * (-1))`, 0, 0]}
                  >
                    <PlanSummary
                      date={date}
                      assessment={assessment}
                      physicianName={providerName}
                      programName={programName}
                    />
                  </Box>
                </GridItem>
              </Grid>
            </Box>
          </Container>
        </GridItem>
      </Grid>
    </Layout>
  );
};

export default AthenaCarePlanPage;
