import {
  StoreOrderFailureError,
  StripeStoreOrderCompleteError,
} from './errors';

type None = {};

export type IntakeContextType = {
  bundleType: BundleType;
  completeStoreOrder: () => Promise<CompleteStoreOrder>;
  isIntakeComplete: boolean;
  orderState: string;
  paymentIntent: string;
  step: number;
  setIntakeStep: (_step: number) => void;
  setIntakeComplete: (_isIntakeComplete: boolean) => void;
  updateBundleOrderState: (state: BundleOrderStates) => void;
};

export type BundleType = 'single' | 'partner';

export enum BundleTypeOptions {
  single = 'single',
  partner = 'partner',
}

export enum BundleOrderStates {
  NOT_STARTED = 'not-started',
  IN_PROGRESS = 'in-progress',
  COMPLETE = 'complete',
  FAILED = 'failed',
  SEND_TO_STRIPE = 'send-to-stripe',
}

export type StoreOrderFailure =
  | StripeStoreOrderCompleteError
  | StoreOrderFailureError;

export interface StripeStoreOrderComplete {
  client_secret: string;
}

export interface CompleteStoreOrder {
  data: StripeStoreOrderComplete | None | null;
  error: StoreOrderFailure | null;
}
