import {
  Button,
  size,
  mediaQueries,
  colors,
  typography,
} from '@everlywell/leaves';
import {
  KitCard,
  BoxImageContainer,
  BoxImage,
  Details,
  Title,
  TestInfo,
} from 'components/KitList/KitCard/styles';
import { Heading } from 'components/KitList/KitList.styles';
import styled from 'styled-components';

const AppointmentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${size.xl1}px;
  padding: ${size.lg}px ${size.md}px 0;

  ${mediaQueries.forTabletVerticalUp} {
    margin: 0 auto ${size.xl3}px;
    max-width: 1157px;
    padding: 0;
  }

  ${mediaQueries.forTabletHorizontalDown} {
    margin: 0 0 ${size.xl3}px;
    padding: ${size.lg}px 7% 0; /* This declaration is to keep the container aligned with the results section */
    width: 100%;
  }

  ${mediaQueries.forPhoneOnly} {
    padding: ${size.md}px;
  }
`;

const AppointmentSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: ${size.md}px;
`;

const AppointmentCard = styled(KitCard)`
  margin-bottom: 0;
  gap: ${size.md}px;

  ${mediaQueries.forTabletHorizontalUp} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const AppointmentDetails = styled(Details)`
  ${typography.bodyText};
`;

const AppointmentDetailsHeading = styled(Title)`
  font-weight: ${typography.weight.regular};
  padding: 0;

  ${mediaQueries.forTabletVerticalUp} {
    padding-left: 0;
  }

  strong {
    font-weight: ${typography.weight.regular};
  }
`;

const AppointmentDetailsSubHeading = styled.p`
  ${typography.bodyTextSmall};
`;

const AppointmentInfo = styled(TestInfo)`
  padding: 0;
  margin-bottom: initial;
  align-items: center;
  gap: ${size.lg}px;
  border-bottom: none;

  ${mediaQueries.forTabletHorizontalUp} {
    padding: 0;
    align-items: center;
  }
`;

const AppointmentBoxImage = styled(BoxImage)`
  border-radius: 100%;
`;

const AppointmentBoxImageContainer = styled(BoxImageContainer)`
  margin: 0;
`;

const AppointmentBlankSlateMessage = styled.p`
  ${typography.bodyText};
`;

const AppointmentContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  ${typography.bodyTextXSmall};

  a {
    font-weight: ${typography.weight.bold};
    text-decoration: none;
    color: ${colors.blue1};

    &:hover {
      text-decoration: underline;
      color: ${colors.blue1};
    }
  }

  ${mediaQueries.forPhoneOnly} {
    text-align: center;
  }
`;

const AppointmentCTAButton = styled(Button)`
  width: 100%;

  & > svg {
    display: inline;
  }
`;

const AppointmentCTAAdditionalInfo = styled.div`
  font-size: 18px;
  width: 100%;
`;

const AppointmentCTAandInfo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${size.sm}px;

  > button {
    width: 100%;
  }

  ${mediaQueries.forTabletHorizontalUp} {
    > button,
    > a {
      width: 240px;
    }
  }

  ${mediaQueries.forTabletVerticalUp} {
    justify-content: flex-end;
  }
`;

export {
  AppointmentContainer,
  AppointmentSection,
  Heading,
  AppointmentInfo,
  AppointmentCard,
  BoxImageContainer,
  AppointmentBoxImage,
  AppointmentBoxImageContainer,
  AppointmentContactInfo,
  AppointmentDetails,
  AppointmentBlankSlateMessage,
  AppointmentCTAandInfo,
  AppointmentCTAButton,
  AppointmentCTAAdditionalInfo,
  AppointmentDetailsHeading,
  AppointmentDetailsSubHeading,
};
