import { colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

import { sharedStyles } from '../../NativeScheduling.styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${sharedStyles.containerStyles};
`;

export const Heading = styled.h1`
  ${typography.h3Text};
  margin: 0;
  padding: 0;
`;

export const DetailText = styled.span`
  display: inline-flex;
  align-items: center;
  ${typography.bodyText};
  font-weight: ${typography.weight.bold};
`;

export const Icon = styled.img`
  margin-right: ${size.md}px;

  path {
    stroke: ${colors.teal4};
    fill: ${colors.white};
  }
`;

export const HelpIcon = styled.img`
  margin-right: ${size.xs2}px;
  width: ${size.lg}px;
  height: ${size.lg}px;
`;

export const HelpTitle = styled.div`
  ${typography.bodyTextSmall};
  font-weight: ${typography.weight.bold};
  color: ${colors.teal4};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HelpBody = styled.span`
  ${typography.bodyTextSmall};
`;

export const HelpPhone = styled.span`
  color: ${colors.blue1};
  white-space: nowrap;
`;

export const NoWrapSpan = styled.span`
  white-space: nowrap;
`;

export const ErrorText = styled.span`
  ${typography.errorText};
`;
