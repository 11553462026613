import { SkeletonText, Text, theme } from '@everlywell/ui-kit';
import useContentfulIntake from 'common/hooks/useContentfulCarePlan';
import { CONTACT_CARE } from 'common/utils/constants';
import { renderRichText } from 'common/utils/helpers';
import React from 'react';

// Types ----------

interface CarePlanInformationProps {}

// Constants ----------

// TODO: Replace testing entry ID when we have real content
// https://app.contentful.com/spaces/ydyvnem5zkxh/entries/2sZo3aMRSv78uu9y65gPPu
const ENTRY_ID = '2sZo3aMRSv78uu9y65gPPu';

// CarePlanInformation ------

const CarePlanInformation: React.FC<CarePlanInformationProps> = (props) => {
  const { isLoading, carePlan } = useContentfulIntake(ENTRY_ID);

  const content = carePlan?.body?.json ?? '';

  return (
    <SkeletonText
      isLoaded={!isLoading}
      noOfLines={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      marginLeft="auto"
      marginRight="auto"
      padding={`${theme.space[6]} ${theme.space[4]}`}
      paddingBottom={0}
      borderTop={`1px solid ${theme.colors.tints.lightGrey}`}
      skeletonHeight={theme.space[4]}
      width="100%"
      sx={{
        '& > div': {
          padding: 0,
        },
      }}
    >
      <Text
        as="div"
        color="tints.uiGrey"
        fontSize="sm"
        textAlign="center"
        sx={{
          a: {
            color: theme.colors.viridian.base,
            fontSize: theme.fontSizes.sm,
          },
        }}
      >
        {content && !isLoading ? (
          renderRichText(content)
        ) : (
          // fallback to Contentful care plan content
          <p>
            {CONTACT_CARE.INITIAL_TEXT}
            <Text
              as="a"
              fontSize="sm"
              color="viridian.base"
              href={`tel:${CONTACT_CARE.PHONE.VALUE}`}
            >
              {CONTACT_CARE.PHONE.NUMBER}
            </Text>
            {CONTACT_CARE.BOTTOM_TEXT}
          </p>
        )}
      </Text>
    </SkeletonText>
  );
};

// Export ---------

export default React.memo(CarePlanInformation);
