import { Button, LocalizeDisabled, size, Tooltip } from '@everlywell/leaves';
import {
  getCarePlanByFormAnswerGroupId,
  TelehealthAppointment,
} from 'common/apis/telehealthApis';
import analytics from 'common/utils/analytics';
import { ANALYTICS } from 'common/utils/constants/analytics';
import { TELEHEALTH_INTERSTITIAL_PAGE_URL } from 'common/utils/constants/urls';
import { appendQueryParamsToUrl } from 'common/utils/helpers';
import { PROGRAM_TYPE } from 'common/utils/types';
import { sub, isWithinInterval, isFuture, parseJSON, parseISO } from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AppointmentDate from 'pages/AccountHub/components/AppointmentDate';
import BaseCard from 'pages/AccountHub/components/BaseCard';
import ErrorCard from 'pages/AccountHub/components/ErrorCard';
import ManageAppointmentModal from 'pages/AccountHub/components/ManageAppointmentModal';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { isPmStatusOccurred, translateStatus } from '../../utils';
import * as S from './AppointmentCard.styles';

export const CTA_CARE_PLAN = 'View care plan';

export const TOOLTIP_UNCOMPLETED_CARE_PLAN =
  'Your provider is still entering your care plan. Check back soon!';
const TOOLTIP_UNCONCLUDED_APPOINTMENT =
  'Your care plan will be ready after your appointment concludes';
const UPCOMING_APPOINTMENT_THRESHOLD = 15; // minutes

export const ASYNC_CLINICIAN = 'Waiting for your clinician to review';

export const ON_DEMAND_CLINICIAN = 'Next Available Clinician';

export const UPCOMING_ASYNC_DESCRIPTION =
  'A provider will be reviewing your online request soon';

export type AppointmentCardProps = {
  appointment: TelehealthAppointment;
};

/**
 * This appointment card is meant for the Telehealth feature to show
 * either past or future appointments
 */
function AppointmentCard({ appointment }: AppointmentCardProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { appointmentManagement } = useFlags();

  const {
    booking_type,
    end_time,
    care_plan,
    status,
    program: { slug },
    provider,
    start_time,
    join_url,
  } = appointment;

  const carePlanId = care_plan?.id;
  const carePlanCompletedAt = care_plan?.completed_at;
  const hasCarePlan = Boolean(carePlanId);
  const programSlug = slug || PROGRAM_TYPE['virtual-care-visit'];

  const { isError, refetch, isLoading } = useQuery(
    ['care-plan-data', carePlanId],
    () => getCarePlanByFormAnswerGroupId(carePlanId!),
    { enabled: hasCarePlan },
  );

  const isManageAppointmentModalEnabled = appointmentManagement;

  const canJoinAppointment = canJoinToTheAppointment(appointment);

  const isFutureAppointment = isFuture(parseJSON(end_time));

  const isOnDemandAppointment = booking_type === 'on_demand';
  const isAppointmentAsync = booking_type === 'async';

  const futureContentLine = isPmStatusOccurred(status)
    ? `Your next appointment is on `
    : `Your appointment on `;
  const contentLine = isFutureAppointment ? (
    <>
      {futureContentLine}
      <AppointmentDate appointmentDate={start_time} showTimezone />
      {!isPmStatusOccurred(status) && ` was ${translateStatus(status)}`}
    </>
  ) : (
    <>
      Your appointment that was scheduled for{' '}
      <AppointmentDate
        appointmentDate={start_time}
        showTime={!isOnDemandAppointment}
        showTimezone
      />{' '}
      was {translateStatus(status)}
    </>
  );

  const telehealthUrl = appendQueryParamsToUrl(
    TELEHEALTH_INTERSTITIAL_PAGE_URL,
    {
      program: programSlug,
      redirectToProvider: 'true',
    },
  );

  const appointmentLink = join_url || telehealthUrl;

  const handleClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        label: canJoinAppointment
          ? ANALYTICS.LABELS.ACCOUNT_HUB.JOIN_APPT
          : ANALYTICS.LABELS.ACCOUNT_HUB.MANAGE_APPT,
        program: programSlug,
      },
    });

    if (!canJoinAppointment && isManageAppointmentModalEnabled) {
      setOpenModal(true);
    }
  };

  const handleCarePlanClick = () => {
    analytics.track({
      event: ANALYTICS.EVENTS.CLICKED_BUTTON,
      data: {
        category: ANALYTICS.CATEGORIES.ACCOUNT_HUB,
        label: ANALYTICS.LABELS.ACCOUNT_HUB.VIEW_CARE_PLAN,
        program: programSlug,
      },
    });
  };

  const isUpcomingOnDemandAppointment =
    isFutureAppointment && isOnDemandAppointment;

  const isCarePlanAvailable = hasCarePlan;
  const isVisible = isPmStatusOccurred(status);

  if (!isLoading && isError && hasCarePlan) {
    return <ErrorCard onRetry={() => refetch()} />;
  }

  const setTitle = () => {
    if (isUpcomingOnDemandAppointment) {
      return ON_DEMAND_CLINICIAN;
    }
    if (isAppointmentAsync && !carePlanCompletedAt) {
      return ASYNC_CLINICIAN;
    }
    return `${provider?.first_name} ${provider?.last_name}`;
  };
  const title = setTitle();

  const setDescription = () => {
    if (!carePlanCompletedAt && isAppointmentAsync)
      return UPCOMING_ASYNC_DESCRIPTION;

    if (carePlanCompletedAt && isAppointmentAsync)
      return (
        <>
          This review was completed on{' '}
          <AppointmentDate
            appointmentDate={carePlanCompletedAt}
            showTimezone={false}
          />
        </>
      );

    if (isUpcomingOnDemandAppointment)
      return "You will receive an email and a text reminder when it's time to join the appointment.";

    return contentLine;
  };

  return (
    <>
      <BaseCard
        content={{
          title: <LocalizeDisabled>{title}</LocalizeDisabled>,
          description: setDescription(),
        }}
        cta={
          <>
            {isVisible && isFutureAppointment && !isAppointmentAsync && (
              <S.Button
                appearance="primary"
                onClick={handleClick}
                href={
                  canJoinAppointment
                    ? appointmentLink
                    : !isManageAppointmentModalEnabled
                    ? telehealthUrl
                    : undefined
                }
                target={isUpcomingOnDemandAppointment ? '_blank' : '_self'}
              >
                {canJoinAppointment ? 'Join appointment' : 'Manage appointment'}
              </S.Button>
            )}

            {isCarePlanAvailable && isVisible && (
              <Button
                appearance="primary"
                component={Link}
                to={`/virtual-care/care-plans?formId=${carePlanId}`}
                css={
                  isFutureAppointment
                    ? { marginTop: `${size.md}px`, width: '100%' }
                    : { width: '100%' }
                }
                onClick={handleCarePlanClick}
              >
                {CTA_CARE_PLAN}
              </Button>
            )}

            {!isCarePlanAvailable &&
              !isFutureAppointment &&
              !isAppointmentAsync &&
              !isUpcomingOnDemandAppointment &&
              isVisible && (
                <Tooltip
                  content={
                    isFutureAppointment
                      ? TOOLTIP_UNCONCLUDED_APPOINTMENT
                      : TOOLTIP_UNCOMPLETED_CARE_PLAN
                  }
                  position="bottom"
                  arrow="center"
                  animationSpeed="normal"
                  css={{ '> div': { width: '100%' } }}
                >
                  <Button
                    appearance="secondary"
                    isDisabled
                    css={
                      isFutureAppointment
                        ? { marginTop: `${size.md}px`, width: '100%' }
                        : { width: '100%' }
                    }
                  >
                    {CTA_CARE_PLAN}
                    <S.InformationIcon />
                  </Button>
                </Tooltip>
              )}
          </>
        }
      />
      <ManageAppointmentModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        program={programSlug}
        appointment={appointment}
      />
    </>
  );
}

export default AppointmentCard;

export const canJoinToTheAppointment = (appointment: TelehealthAppointment) => {
  const { start_time, end_time, booking_type } = appointment;

  if (booking_type === 'on_demand') {
    // on_demand appointments are always joinable
    return true;
  }

  return isWithinInterval(parseJSON(Date.now()), {
    start: sub(parseISO(start_time), {
      minutes: UPCOMING_APPOINTMENT_THRESHOLD,
    }),
    end: parseISO(end_time),
  });
};
