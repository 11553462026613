import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { datadogLogs } from '@datadog/browser-logs';
import { mediaQueries, utils, localizeUtils } from '@everlywell/leaves';
import { TelehealthAppointment } from 'common/apis/telehealthApis';
import { setProgramSlug } from 'common/hooks/useProgramSlug/useProgramSlug';
import { validDateFormat } from 'common/hooks/useRegistrationUser';
import analytics from 'common/utils/analytics';
import {
  HEADER_HEIGHT,
  MARKER_VALUE_EXCEPTIONS,
  RGB_DICTIONARY,
} from 'common/utils/constants';
import { ANALYTICS } from 'common/utils/constants/analytics';
import CryptoES from 'crypto-es';
import {
  addMinutes,
  closestTo,
  differenceInDays,
  differenceInMonths,
  eachHourOfInterval,
  endOfDay,
  format,
  formatDistanceStrict,
  isWithinInterval,
  parseISO,
  parseJSON,
  startOfDay,
  sub,
} from 'date-fns';
import Cookies from 'js-cookie';
import { find, isNull, isUndefined } from 'lodash-es';
import moment from 'moment';
import React, { RefObject, useState } from 'react';

import { PWN_CONSULT_TESTS } from './constants/features';
import { DTC_STATES } from './constants/states';
import {
  PWN_CONSULT_ROOT,
  TELEHEALTH_INTERSTITIAL_PAGE_URL,
} from './constants/urls';
import { REGISTRATION_KEYS } from './registrationHelpers';
import {
  ActiveMembershipStatuses,
  BaseRegistrationInput,
  CovidRegistrationPayload,
  InputError,
  KitRegistrationInput,
  KitRegistrationUser,
  KitResult,
  Marker,
  MarkerWithResult,
  MembershipStatus,
  EnterpriseAttributes,
} from './types';

type StateWithTrackingArgs = {
  defaultState: boolean;
  inactiveLabel: string;
  activeLabel: string;
  callback: Function;
};

/**
 * returns the value of a url query parameter
 * @param name the url parameter looking for
 * @param url url in which to look
 */

export function getParameterByName(name: string, url: string) {
  const escapedName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${escapedName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url || window.location.href);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
 * copy any string to the clipboard
 * returns a string with the copy status
 * @param stringToCopy the string you wish to copy to clipboard
 */
export function copyToClipboard(stringToCopy: string) {
  try {
    const el = document.createElement('textarea');
    const range = document.createRange();

    el.value = stringToCopy;
    el.setAttribute('contenteditable', 'true');
    el.setAttribute('readonly', 'false');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);

    range.selectNodeContents(el);

    const selection = window.getSelection();
    if (selection) {
      selection.removeAllRanges();
      selection.addRange(range);
    }

    el.select(); // For Chrome
    el.setSelectionRange(0, 99999); // For iOS & Others

    document.execCommand('copy');
    document.body.removeChild(el);
    return `Successfully copied ${stringToCopy}`;
  } catch (err) {
    return `Failed copying ${stringToCopy}: ${err}`;
  }
}

export function calculateComponentHeight(
  ref: RefObject<HTMLDivElement> | null,
  callback: Function,
) {
  if (!ref) return;
  const component = ref.current;
  if (component) {
    const contentHeight = component.scrollHeight;
    callback(contentHeight);
  }
}

/**
 * add analytics tracking to setState hooks used for toggling boolean values
 *
 * returns a stateful value and a function to track and toggle it
 * @param args an object containing the defaultState and (in)active labels used
 * for analytics tracking
 *
 * example:
 *   const [myState, myStateFn] = useToggleStateWithTracking({
 *     defaultState: false,
 *     inactiveLabel: 'Show more details',
 *     activeLabel: 'Hide details',
 *     callback: (boolVal) => func(boolVal),
 *   })
 */
export function useToggleStateWithTracking(
  args: StateWithTrackingArgs,
): [boolean, Function] {
  const { defaultState, inactiveLabel, activeLabel, callback } = args;
  const [state, setState] = useState(defaultState);

  const setStateWithTracking = () => {
    setState((prevState: boolean) => {
      callback(prevState);
      analytics.track({
        event: ANALYTICS.EVENTS.CLICKED_BUTTON,
        data: {
          label: prevState ? activeLabel : inactiveLabel,
          category: ANALYTICS.CATEGORIES.EXPLORE_MARKERS,
        },
      });
      return !prevState;
    });
  };

  return [state, setStateWithTracking];
}

/**
 * check if marker value is an exception as defined in the constants file
 *
 * returns a boolean value
 * @param markerValue a string to check against the existing array of
 * pre-defined exception values
 */
export function isMarkerValueException(markerValue: string): boolean {
  return MARKER_VALUE_EXCEPTIONS.includes(markerValue);
}

export function jumpToSection(
  enableJump: boolean,
  sectionId: string,
  smooth?: boolean,
) {
  const jumpEl = document.getElementById(sectionId);
  if (enableJump && jumpEl) {
    const oldOffset = window.pageYOffset || document.documentElement.scrollTop;
    let scrollOpts;
    if (smooth) {
      scrollOpts = { behavior: 'smooth' };
    }

    // @ts-ignore-next-line scrollOpts is fine
    jumpEl.scrollIntoView(scrollOpts);
    if (
      oldOffset > window.pageYOffset ||
      oldOffset > document.documentElement.scrollTop
    ) {
      // header shows when scrolling up so make room for it
      window.scrollBy(0, -HEADER_HEIGHT);
    }
  }
}

export function jumpToAnchor(
  component: string,
  method: string,
  timeout?: number,
) {
  const timeoutVal = timeout ?? 1300;
  return setTimeout(() => {
    try {
      const targetAnchor =
        window.location.hash && window.location.hash.substring(1);

      if (targetAnchor) jumpToSection(true, targetAnchor, true);
    } catch (err) {
      const error = err as Error;
      logError(error.message, {
        component,
        method,
      });
    }
  }, timeoutVal);
}

export function isCookieTruthy(
  cookieName: string,
  cookieValue: string,
): boolean {
  return Cookies.get(cookieName) === cookieValue;
}

export function isHumanaCustomer(): boolean {
  return isCookieTruthy('thirdparty', 'humana');
}

/**
 * All the members that came from /members login page + Humana portal
 * are considered Enterprise Customers. And during their authentication flow
 * the `thirparty` cookie is added for them.
 *
 * This helper checks the cookie exists and it is not empty
 */
export const isEnterpriseCustomer = () => Boolean(Cookies.get('thirdparty'));

export const isEnterpriseTelehealth = (
  enterprise_attributes: EnterpriseAttributes,
): boolean =>
  Boolean(
    enterprise_attributes?.partner_slug &&
    enterprise_attributes?.enterprise_telehealth_program_slug &&
    enterprise_attributes?.test_to_treat_program_slug,
  );

export const setEnterpriseTelehealthValues = (
  partner_slug: string,
  program_slug: string,
) => {
  if (!Boolean(Cookies.get('thirdparty'))) {
    Cookies.set('thirdparty', partner_slug);
  }

  if (program_slug) {
    setProgramSlug(program_slug);
  }
};

type FaqOneAvailableTypes = {
  FAQ_TOOLTIP_1_HEADER: string;
  FAQ_TOOLTIP_1_DESCRIPTION: string;
};
export const isFaqOneAvailableForConsumers = ({
  FAQ_TOOLTIP_1_HEADER,
  FAQ_TOOLTIP_1_DESCRIPTION,
}: FaqOneAvailableTypes) =>
  Boolean(FAQ_TOOLTIP_1_HEADER) &&
  Boolean(FAQ_TOOLTIP_1_DESCRIPTION) &&
  !isEnterpriseCustomer();

export function isNonHumanaEnterpriseCustomer() {
  return isEnterpriseCustomer() && !isHumanaCustomer();
}

export function getTimestampString(
  historicalTimestamp: string,
  latestTimestamp: string,
): string {
  const latest = parseISO(latestTimestamp);
  const historical = parseISO(historicalTimestamp);
  const diffInMonths = differenceInMonths(latest, historical);

  if (diffInMonths <= 0) {
    return `${differenceInDays(latest, historical)} days`;
  }

  if (diffInMonths === 12) {
    return '1 year';
  }

  if (diffInMonths > 12) {
    return '1+ year';
  }

  return `${formatDistanceStrict(historical, latest)}`;
}

export function getNumberOfDays(available_until: string): number {
  const endDate = new Date(available_until);
  const today = new Date();
  const diffTime = Math.abs(endDate.getTime() - today.getTime());
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
}

export function generatePWNConsultLink(
  pwnOrderNumber: number,
  pwnConfirmationNumber: number,
  testName: string,
): string {
  return `${PWN_CONSULT_ROOT}/c/intake/partners/everlywell-com/new?service_id=${getServiceIDForTestName(
    testName,
  )}&req_number=${pwnOrderNumber}&confirmation_code=${pwnConfirmationNumber}`;
}

function getServiceIDForTestName(testName: string) {
  switch (testName) {
    case 'Thyroid Test':
      return 'thyroid-initial';
    case 'Cholesterol and Lipids Test':
    case 'Heart Health Test':
      return 'lipids-initial';
    default:
      return 'phis';
  }
}

export function showConsultForName(
  kitResult: KitResult,
  name: string,
): boolean {
  const { consult } = kitResult;
  if (!consult || !consult.available) {
    return false;
  }
  const currentUtcTime = new Date().toISOString();
  return (
    currentUtcTime < consult.available_until && PWN_CONSULT_TESTS.includes(name)
  );
}

export const logError = (error: string, context?: object | undefined) => {
  datadogLogs.logger.error(error, context);
};

export function toTitleCase(str: string) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(),
  );
}

const getTimeByHalfHour = () =>
  eachHourOfInterval({
    start: startOfDay(new Date()),
    end: endOfDay(new Date()),
  }).flatMap((item) => [item, addMinutes(new Date(item), 30)]);

export function getListOfCollectionTimes() {
  return getTimeByHalfHour()
    .map((item, index) => ({
      value: format(item, 'hh:mm aa'),
      id: index.toString(),
    }))
    .sort(
      (a, b) =>
        new Date(`1970/01/01 ${a.value}`).getTime() -
        new Date(`1970/01/01 ${b.value}`).getTime(),
    );
}

export function getCurrentTime() {
  // @ts-ignore
  return format(closestTo(new Date(), getTimeByHalfHour()), 'hh:mm aa');
}

export function getCurrentDate() {
  return format(new Date(), 'yyyy-MM-dd');
}

export function getTimezoneFromBrowser() {
  const date = new Date().toString();
  let offset: string;
  const matches = date.match(/([-+][0-9]+)\s/);
  if (!matches || matches.length < 2) {
    offset = '+0000';
  } else {
    [offset] = matches;
  }
  return offset.trim();
}

export function parseYesNoValueForPayload(value: string) {
  return value.includes('no') ? 'no' : 'yes';
}

export function parseValueForPayload(value: string) {
  return /prefer-not-to.*/.test(value)
    ? 'prefer-not-to-answer'
    : parseYesNoValueForPayload(value);
}

export function replaceHyphenForValue(
  input: BaseRegistrationInput<string> | KitRegistrationInput<string>,
): string {
  return input.value.replace(/-/g, ' ');
}

export function buildCOVIDPayload(
  user: KitRegistrationUser,
): CovidRegistrationPayload {
  const basePayload: CovidRegistrationPayload = {
    symptoms_level: replaceHyphenForValue(user.symptoms), // "no symptoms" "mild" "severe"
    COVID_symptoms: parseYesNoValueForPayload(user.symptoms.value),
    symptoms_start_date: moment(user.symptomsDate.value).isValid()
      ? moment(user.symptomsDate.value).format('YYYY-MM-DD')
      : user.symptomsDate.value, // "" || "03/24/2021"
    exposure: replaceHyphenForValue(user.exposure), // "not exposed" || 'area community spread' || 'sick contact' || 'known exposure'
    comorbidities: replaceHyphenForValue(user.highRisk), // 'low risk' || 'high risk'
    congregate_setting: parseYesNoValueForPayload(user.congregateSetting.value),
    employed_healthcare_setting: parseYesNoValueForPayload(
      user.healthcareSetting.value,
    ),
    first_COVID_test: parseYesNoValueForPayload(user.firstCovidTest.value),
    current_ICU: 'unknown',
    currently_hospitalized: 'unknown',
  };
  basePayload.COVID_vaccine_data = {
    received: parseValueForPayload(user.vaccinated.value), // "yes", "no", "prefer-not-to-answer"
  };
  if (parseYesNoValueForPayload(user.vaccinated.value) === 'yes') {
    basePayload.COVID_vaccine_data = {
      ...basePayload.COVID_vaccine_data,
      additional_doses: user.covidVaccineData.value.additional_doses,
      doses: user.covidVaccineData.value.doses ?? 0,
      first_dose_date: moment(
        user.covidVaccineData.value.first_dose_date,
      ).isValid()
        ? moment(user.covidVaccineData.value.first_dose_date).format(
          'YYYY-MM-DD',
        )
        : user.covidVaccineData.value.first_dose_date,
      name: user.covidVaccineData.value.name?.trim(),
      second_dose_name: user.covidVaccineData.value.second_dose_name?.trim(),
      second_dose_date: moment(
        user.covidVaccineData.value.second_dose_date,
      ).isValid()
        ? moment(user.covidVaccineData.value.second_dose_date).format(
          'YYYY-MM-DD',
        )
        : user.covidVaccineData.value.second_dose_date,
    };
  }
  if (user.newYorkResident.value) {
    const livesInNewYork = parseYesNoValueForPayload(
      user.newYorkResident.value,
    );
    basePayload.live_in_ny = livesInNewYork;
    if (livesInNewYork === 'yes') {
      const employedInNewYork = parseYesNoValueForPayload(
        user.newYorkEmployment.value,
      );
      const studentInNewYork = parseYesNoValueForPayload(
        user.newYorkStudent.value,
      );
      basePayload.employed_in_ny = employedInNewYork;
      basePayload.student_in_ny = studentInNewYork;
      if (employedInNewYork === 'yes') {
        basePayload.employer = {
          name: user.employerName.value,
          address1: user.employerStreetAddress.value,
          address2: user.employerSubAddress.value,
          city: user.employerCity.value.trim(),
          state: user.employerState.value,
          zipcode: user.employerZipCode.value.trim(),
        };
      }
      if (studentInNewYork === 'yes') {
        basePayload.student = {
          name: user.newYorkSchoolName.value,
          address1: user.schoolStreetAddress.value,
          address2: user.schoolSubAddress.value,
          city: user.schoolCity.value,
          state: user.schoolState.value,
          zipcode: user.schoolZipCode.value,
        };
      }
    }
  }
  return basePayload;
}

export const useFormattedDate = (date?: any) => {
  const [prevDate, setPrevDate] = useState(
    date ? date.replace(/-|\//g, '') : '',
  );

  const formatDate = (value: any) => {
    const formattedDate = value.replace(/-|\//g, '').split('');

    if (prevDate.length >= formattedDate.length) {
      setPrevDate(formattedDate.join(''));
      return value;
    }

    if (value[value.length - 1].match(/![0-9]/)) {
      return prevDate;
    }

    setPrevDate(formattedDate.join(''));

    if (formattedDate.length >= 2) {
      formattedDate.splice(2, 0, '/');
    }

    if (formattedDate.length >= 5) {
      formattedDate.splice(5, 0, '/');
    }

    return formattedDate.join('');
  };
  return formatDate;
};

export function isDateValid(
  value: string,
  start: Date = new Date(1900, 1, 1),
  end: Date = new Date(),
  error?: string,
): InputError {
  const isValidDate = validDateFormat(value);
  if (!isValidDate) return 'Please enter a valid date';

  const formattedValue = moment(value).format('YYYY-MM-DD');

  const withinInterval = isWithinInterval(new Date(formattedValue), {
    start,
    end,
  });

  // If valid date, but over ~130 years old entered
  // @ts-ignore
  if ((end - new Date(formattedValue)) / (1000 * 60 * 60 * 24 * 365) > 110) {
    return 'Please enter a valid date';
  }

  const formattedStart = format(start, 'MMMM do yyyy');
  const formattedEnd = format(end, 'MMMM do yyyy');

  const LocalizeStart = localizeUtils.wrapTimeAgoInLocalizeVar(
    `${formattedStart}`,
    'isDateValidLocalize-start',
  );
  const LocalizeEnd = localizeUtils.wrapTimeAgoInLocalizeVar(
    `${formattedEnd}`,
    'isDateValidLocalize-end',
  );

  const genericError = (
    <>
      <span data-isolate>Please enter a date between</span> {LocalizeStart}{' '}
      <span data-isolate>and</span> {LocalizeEnd}
    </>
  );

  return withinInterval ? '' : error || genericError;
}

export async function clearSessionStorage(keys: string[]) {
  await Promise.all(
    keys.map(async (key: string) => {
      try {
        await window.sessionStorage.removeItem(key);
      } catch (err) {
        const error = err as Error;
        logError(error.message, error);
      }
    }),
  );
}

export const slugFormatting = (formSlug: string): string =>
  toTitleCase(formSlug).replaceAll('-', ' ');

export function formatPhoneNumber(value: string) {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, '');
  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
    3,
    6,
  )}-${phoneNumber.slice(6, 10)}`;
}

export function formatKitId(value: string) {
  if (!value) return value;

  const formatedBarcode = value.replace(new RegExp('-', 'g'), '');
  switch (formatedBarcode.length) {
    case 8:
      return `${formatedBarcode.slice(0, 2)}-${formatedBarcode.slice(
        2,
        5,
      )}-${formatedBarcode.slice(5, 8)}`;
    case 9:
      return `${formatedBarcode.slice(0, 3)}-${formatedBarcode.slice(
        3,
        6,
      )}-${formatedBarcode.slice(6, 9)}`;
    case 10:
      return `${formatedBarcode.slice(0, 3)}-${formatedBarcode.slice(
        3,
        6,
      )}-${formatedBarcode.slice(6, 10)}`;
    case 11:
      return `${formatedBarcode.slice(0, 3)}-${formatedBarcode.slice(
        3,
        7,
      )}-${formatedBarcode.slice(7, 11)}`;
    case 12:
      return `${formatedBarcode.slice(0, 4)}-${formatedBarcode.slice(
        4,
        8,
      )}-${formatedBarcode.slice(8, 12)}`;
    default:
      return formatedBarcode.match(/.{1,3}/g)!.join('-');
  }
}

export function replaceQueryString(urlParams: string, query: string) {
  if (urlParams.includes(query)) {
    let search = urlParams
      .replace(new RegExp(`(${query}=(\\w*))`), '')
      .replace('&&', '&');
    if (search[1] === '&') {
      search = `?${search.slice(2)}`;
    }
    if (search[search.length - 1] === '&') {
      search = search.substring(0, search.length - 1);
    }
    return search;
  }
  return urlParams;
}

export const zipcodeCleaningValidation = (val: string) => {
  try {
    const cleanedVal = val.replace(/[^0-9]/g, '').substring(0, 5);
    return cleanedVal;
  } catch (err) {
    const error = err as Error;
    logError(error.message, {
      method: 'zipcodeCleaningValidation',
    });
    return val;
  }
};

export const isValidInput = (
  value: number,
  min: number,
  max: number,
  unit: string,
) => {
  let isValid;

  if (value < min || value > max) {
    isValid = false;
  } else {
    isValid = true;
  }

  return isValid
    ? ''
    : `Please enter a value between ${min} and ${max} ${unit}.`;
};

export const isActiveMember = (state?: MembershipStatus): boolean =>
  !!(state && ActiveMembershipStatuses.includes(state));

export const stickyUnit = (e: React.ChangeEvent<HTMLInputElement>) => {
  const caret = e.target.selectionStart;
  const element = e.target;
  window.requestAnimationFrame(() => {
    element.selectionStart = caret;
    element.selectionEnd = caret;
  });
};

export const isValidFullName = (
  inputStr: string,
  user: KitRegistrationUser,
) => {
  const userFullName = `${user.firstName.value
    .toLocaleLowerCase()
    .trim()} ${user.lastName.value.toLocaleLowerCase().trim()}`;
  return inputStr.toLocaleLowerCase().trim() === userFullName;
};

export const hasRatedPersonalization = (
  widget: string,
  label?: string,
): boolean => {
  const key = `${label} - ${widget}-viewed`;
  const personalization = localStorage.getItem(
    label ? key : `${widget}-viewed`,
  );
  if (isNull(personalization) || isUndefined(personalization)) {
    return false;
  }
  return true;
};

const findUserStateInfo = (stateId: number) =>
  find(DTC_STATES, (stateInfo = []) => stateInfo[0] === stateId);

export const userStateIdToName = (stateId?: number) => {
  if (!stateId) {
    return '';
  }

  const userStateInfo = findUserStateInfo(stateId);
  if (!userStateInfo) {
    return '';
  }

  return userStateInfo[1];
};

export const userStateNameToId = (stateName?: string) => {
  if (!stateName) {
    return '';
  }

  const userStateInfo = find(
    DTC_STATES,
    (stateInfo = []) => stateInfo[1] === stateName,
  );

  if (!userStateInfo) {
    return '';
  }

  return userStateInfo[0];
};

export function hexToRGB(hexColor: string) {
  if (hexColor.length !== 6) {
    new Error('Only six-digit hex colors are allowed.');
  }
  const rbgColorObj = utils.hexToRgb(hexColor);
  const { r, g, b } = rbgColorObj!;
  return `${r}, ${g}, ${b}`;
}

function parseColorNameToRGB(colorName: string, markerID: number) {
  const rgbColor = RGB_DICTIONARY[colorName];
  if (isNull(rgbColor) || isUndefined(rgbColor)) {
    throw new Error(
      `RGB color not found for color name: ${colorName} on marker: ${markerID}`,
    );
  }
  return rgbColor;
}

export function getRGBColorBySeverityIndex(
  severityIndex: number,
  marker: Marker | MarkerWithResult,
) {
  try {
    const colorName = marker.severity_colors[severityIndex];
    return parseColorNameToRGB(colorName, marker.id);
  } catch (error: any) {
    logError(error.message, { error, method: 'getRGBColorBySeverityIndex' });
    return RGB_DICTIONARY['default'];
  }
}

export function getRGBRangeColors(marker: Marker) {
  try {
    return marker.severity_colors.map((colorName) =>
      parseColorNameToRGB(colorName, marker.id),
    );
  } catch (error: any) {
    logError(error.message, { error, method: 'getRGBRangeColors' });
    return [RGB_DICTIONARY['default']];
  }
}

// Intersection Observer threshold value
export const getIsVisibleThreshold = () => {
  let threshold = 0.1;
  if (window.innerWidth <= Number(mediaQueries.forPhoneOnly)) {
    threshold = 0.2;
  } else if (window.innerWidth <= Number(mediaQueries.forTabletVerticalDown)) {
    threshold = 0.3;
  } else if (
    window.innerWidth <= Number(mediaQueries.forTabletHorizontalDown)
  ) {
    threshold = 0.4;
  }

  return threshold;
};

export const truncate = (text: string, limit = 100) => {
  if (!text) {
    return null;
  }

  return text?.length > limit ? `${text?.substring(0, limit - 3)}...` : text;
};

export const appendQueryParamsToUrl = (
  url: string,
  queryParams: Record<string, string>,
) => {
  const parsedUrl = new URL(url);

  Object.entries(queryParams).forEach(([key, value]) => {
    parsedUrl.searchParams.set(key, value);
  });

  return parsedUrl.toString();
};

// SHA1 hash a string, and convert to base64.
// The use of SHA1 has been generally deprecated since 2010.
// We're using this for GTM tags as a marketing request.
// If you need to hash a string, you might consider using something more secure and modern.
export const hashSha1 = (rawMessage: string) => {
  const hashRawMessage = CryptoES.SHA1(rawMessage);
  return CryptoES.enc.Base64.stringify(hashRawMessage);
};

export const telehealthUrl = (options: Record<string, string>): URL => {
  const telehealthUrl = appendQueryParamsToUrl(
    TELEHEALTH_INTERSTITIAL_PAGE_URL,
    options,
  );
  return new URL(telehealthUrl);
};

export const clearUserLocalStorageAndSession = () => {
  window.localStorage.removeItem('jwtToken');
  window.localStorage.removeItem('userId');
  window.localStorage.removeItem('username');
  Cookies.remove('thirdparty');
  Cookies.remove('healthplan_slug');
  Cookies.remove('healthplan');
  clearSessionStorage(REGISTRATION_KEYS);
};

/**
 * Removes specified query parameters from a given URL.
 * @param url - The URL from which parameters will be removed.
 * @param paramsName - An array of query parameter names to be removed from the URL.
 * @returns The updated URL without the specified query parameters.
 */
export const removeParamsFromUrl = (
  url: string,
  paramsName: string[],
): string => {
  const urlObj = new URL(url);
  const params = new URLSearchParams(urlObj.search);

  paramsName.forEach((param) => params.delete(param));

  urlObj.search = params.toString();
  return urlObj.toString();
};

export const nameValuePairsToDropdownOptionLowerCase = (name: string) => {
  const value = name.toLowerCase().replace(/ /g, '_');
  return {
    name,
    value,
  };
};

export const isAsyncProgram = (programType: string) => programType === 'async';

export const renderRichText = (
  json: JSON | string,
  options?: Options,
): React.ReactNode | null => {
  let component = null;

  try {
    const jsonObj = typeof json === 'string' ? JSON.parse(json ?? '{}') : json;
    component = documentToReactComponents(jsonObj, options);
  } catch (error) {
    logError('Error parsing rich text raw content.', {
      component: 'renderRichText',
      function: 'renderRichText',
      stackTrace: error,
    });
  }

  return component;
};

const UPCOMING_APPOINTMENT_THRESHOLD = 15; // minutes

export const canJoinToTheAppointment = (appointment: TelehealthAppointment) => {
  const { start_time, end_time, booking_type } = appointment;

  if (booking_type === 'on_demand') {
    // on_demand appointments are always joinable
    return true;
  }

  return isWithinInterval(parseJSON(Date.now()), {
    start: sub(parseISO(start_time), {
      minutes: UPCOMING_APPOINTMENT_THRESHOLD,
    }),
    end: parseISO(end_time),
  });
};
