import { size, colors, mediaQueries } from '@everlywell/leaves';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ inView: boolean }>`
  display: flex;
  position: sticky;
  bottom: 0;
  background-color: ${colors.white};
  margin-top: ${size.xl1}px;
  padding: ${size.md}px 0;
  z-index: 1;

  ${mediaQueries.forTabletVerticalUp} {
    padding: ${size.lg}px 0;
  }

  ${({ inView }) =>
    !inView &&
    css`
      box-shadow: 0 ${size.xs2}px ${size.sm}px rgba(0, 0, 0, 0.1);
      margin-left: -${size.md}px;
      margin-right: -${size.md}px;
      padding: ${size.md}px;
      width: 100vw;

      ${mediaQueries.forTabletVerticalUp} {
        margin-left: 0;
        margin-right: 0;
        padding: ${size.md}px 0;
        width: 100%;
      }
      .grid-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: ${size.md}px;
      }

      .grid-item {
        padding: 0;
      }
    `};
`;

export const InnerContainer = styled.div`
  width: 100%;
  padding-bottom: env(safe-area-inset-bottom, 50px);
`;

export const InViewTrigger = styled.div`
  width: 100%;
  display: flex;
`;
