import { TelehealthAppointment } from 'common/apis/telehealthApis';
import { PATIENT_PORTAL } from 'common/utils/constants/urls';
import { canJoinToTheAppointment } from 'common/utils/helpers';
import AppointmentDate from 'pages/AccountHub/components/AppointmentDate';
import CancelAppointmentCTA from 'pages/OldDashboardPage/Appointments/CancelAppointmentCTA/CancelAppointmentCTA';
import React from 'react';

import { AppointmentCardProps } from '../AppointmentCard/AppointmentCard';

export type FormattedAppointment = Omit<AppointmentCardProps, 'boxImage'> & {
  id: string;
};

export type FormatAppointmentArgs = {
  appointment: TelehealthAppointment;
  providerFullName: string;
  formattedTime: string;
};

export const handleCanceledOrNoShow = ({
  appointment,
  providerFullName,
  formattedTime,
}: FormatAppointmentArgs): FormattedAppointment => {
  const status = appointment.status === 'no-show' ? 'missed' : 'canceled';

  return {
    id: appointment.id,
    heading: (
      <AppointmentDate
        appointmentDate={appointment.start_time}
        showTime={false}
        showTimezone={false}
        showDayOfWeek={false}
        options={{ dayPattern: 'do' }}
      />
    ),
    subHeading: `Your appointment that was scheduled with Dr. ${providerFullName} at ${formattedTime} was ${status}`,
  };
};

/**
 * Handle appointment that has occurred
 * @param appointment
 * @param providerFullName
 * @param formattedTime
 * @param isAfterThreePlusHours
 * @returns formatted appointment
 */
export const handleOccurred = ({
  appointment,
  providerFullName,
  formattedTime,
  isAfterThreePlusHours,
}: FormatAppointmentArgs & {
  isAfterThreePlusHours: boolean;
}): FormattedAppointment => ({
  id: appointment.id,
  canDisplayCTA: isAfterThreePlusHours,
  heading: (
    <AppointmentDate
      appointmentDate={appointment.start_time}
      showTime={false}
      showTimezone={false}
      showDayOfWeek={false}
      options={{ dayPattern: 'do' }}
    />
  ),
  subHeading: `You met with Dr. ${providerFullName} at ${formattedTime}`,
  callToAction: isAfterThreePlusHours
    ? {
        appearance: 'secondary',
        content: 'View Care Plan',
        href: PATIENT_PORTAL,
        otherProps: {
          target: '_blank' as string,
        },
      }
    : undefined,
});

/**
 * Handle appointment that is today
 * @param appointment
 * @param providerFullName
 * @returns formatted appointment
 */
export const handleTodayAppointment = ({
  appointment,
  providerFullName,
}: Omit<
  FormatAppointmentArgs,
  'formattedDate' | 'formattedTime'
>): FormattedAppointment => {
  const cta = canJoinToTheAppointment(appointment)
    ? {
        appearance: 'primary' as NonNullable<
          AppointmentCardProps['callToAction']
        >['appearance'],
        content: 'Join Appointment',
        href: appointment?.join_url!,
        additionalContent: (
          <>
            <CancelAppointmentCTA
              appointmentId={appointment.id}
              appointmentDate={appointment.start_time}
              providerFullName={providerFullName}
            />
          </>
        ),
        otherProps: {
          hasArrow: true,
          target: '_self',
        },
      }
    : {
        appearance: 'primary' as NonNullable<
          AppointmentCardProps['callToAction']
        >['appearance'],
        content: 'Go to Everlywell Patient Portal',
        href: PATIENT_PORTAL,
        additionalContent: (
          <>
            <CancelAppointmentCTA
              appointmentId={appointment.id}
              appointmentDate={appointment.start_time}
              providerFullName={providerFullName}
            />
          </>
        ),
        otherProps: {
          target: '_blank',
          hasArrow: false,
        },
      };

  return {
    id: appointment.id,
    canDisplayCTA: true,
    heading: (
      <>
        Virtual visit today,{' '}
        <AppointmentDate
          appointmentDate={appointment.start_time}
          showTime={false}
          showTimezone={false}
          showDayOfWeek={false}
          options={{ dayPattern: 'do' }}
        />
      </>
    ),
    subHeading: (
      <>
        You will be joining Dr. {providerFullName} <strong>today</strong>,{' '}
        <AppointmentDate
          appointmentDate={appointment.start_time}
          showTimezone={false}
          showDayOfWeek={false}
          options={{ dayPattern: 'do' }}
        />
      </>
    ),
    callToAction: { ...cta },
  };
};

/**
 * Handle appointment that is after today
 * @param appointment
 * @param providerFullName
 * @returns formatted appointment
 */
export const handleFutureAppointment = ({
  appointment,
  providerFullName,
}: Omit<
  FormatAppointmentArgs,
  'formattedDate' | 'formattedTime'
>): FormattedAppointment => ({
  id: appointment.id,
  canDisplayCTA: true,
  heading: (
    <>
      Virtual visit on{' '}
      <AppointmentDate
        appointmentDate={appointment.start_time}
        showTime={false}
        showTimezone={false}
        options={{ dayPattern: 'do' }}
      />
    </>
  ),
  subHeading: (
    <>
      You will be meeting with Dr. {providerFullName} on{' '}
      <AppointmentDate
        appointmentDate={appointment.start_time}
        showTimezone={false}
        showDayOfWeek={false}
        options={{ dayPattern: 'do' }}
      />
    </>
  ),
  callToAction: {
    appearance: 'primary',
    content: 'Go to Everlywell Patient Portal',
    href: PATIENT_PORTAL,
    additionalContent: (
      <>
        <CancelAppointmentCTA
          appointmentId={appointment.id}
          appointmentDate={appointment.start_time}
          providerFullName={providerFullName}
        />
      </>
    ),
    otherProps: {
      target: '_blank',
    },
  },
});
