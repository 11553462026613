import { useQuery as useApolloClientQuery } from '@apollo/client';
import { ATHENA_CARE_PLANS } from 'common/utils/contentfulGraphQlQueries';
import { logError } from 'common/utils/helpers';
import { useState } from 'react';

export interface CarePlan {
  sectionModel: {
    eyebrow: string;
    displayName: string;
    __typename: string;
    body: {
      json: JSON;
    };
  };
}

export default function useContentfulCarePlan(entryId: string) {
  const [carePlan, setCarePlan] = useState<CarePlan['sectionModel']>(
    {} as CarePlan['sectionModel'],
  );
  const { loading: isLoading } = useApolloClientQuery<CarePlan>(
    ATHENA_CARE_PLANS,
    {
      variables: {
        entryId,
      },
      onError(error) {
        logError((error as Error).message, {
          errorInfo: 'Graphql fetching Error',
          component: 'useContentfulCarePlan',
          method: 'useApolloClientQuery',
        });
      },
      skip: Object.keys(carePlan).length > 0,
      onCompleted: (data) => {
        if (!data?.sectionModel) return;
        setCarePlan(data?.sectionModel);
      },
    },
  );

  return {
    carePlan,
    isLoading,
  };
}
