import { H5, colors, size, typography } from '@everlywell/leaves';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${size.md}px;
  gap: ${size.xs2}px;
`;

export const Heading = styled(H5)`
  margin: 0;
  font-size: ${size.md}px;
`;

export const Header = styled.div`
  display: inline-flex;
  gap: ${size.xs2}px;
  align-items: center;
`;

export const Text = styled.p`
  ${typography.bodyTextSmall};
  text-align: center;
  margin: 0;

  a {
    color: ${colors.blue1};
    text-decoration: none;
    font-weight: inherit;
  }
`;
